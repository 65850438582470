<template>
  <div list-card-thumb :class="{info}">
    <p v-show="tag"><em>{{ tag }}</em></p>
    <ImageAsset v-if="info && gameImg" :url="gameImg" class="game-img" bg-color="black" />
    <ImageAsset :url="image" :bg-color="backgroundColor" fit="cover" />
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils';
import ImageAsset from '@/views/components/common/ImageAsset.vue';

export default {
  name: 'ListCardThumb',
  components: { ImageAsset },
  props: {
    info: Boolean,
    state: {
      type: String,
      default: '',
    },
    statusTag: {
      type: String,
      default: '',
    },
    schedule: {
      type: Object,
      default: () => {
      },
    },
    backgroundColor: {
      type: String,
      default: null,
    },
    image: {
      type: String,
      default: '',
    },
    gameId: { type: String },
  },
  computed: {
    games: state('info', 'games'),
    tag() {
      switch (this.statusTag) {
        case 'PRE':
          return '접수대기';
        case 'READY':
          return '접수중';
        case 'WAITING_APPLY':
          return '접수대기';
        case 'APPLYING':
          return '접수중';
        case 'ONGOING':
          return '진행중';
        case 'END':
          return '종료';
        default:
          return null;
      }
    },
    gameImg() {
      return this.games.find(item => item.id === this.gameId)?.images.colorIcon;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[list-card-thumb] { .rel;
  [image-asset] {.wh(100%); .br(8);}
  &.info {
    [image-asset] {.wh(100%); .br(0);}
    .game-img { .abs; .lt(6, 6); .z(1); .wh(24); .br(50%); }
  }

  @media (@tl-up) {
    &.info {
      .game-img { .lt(12, 12); .wh(32); }
    }
  }
  @media (@ds-up) {

  }
}
</style>
