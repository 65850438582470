<template>
  <div official-league>
    <PSection class="progress-holder" theme="black">
      <div class="inside">
        <div class="title">
          <SectionTitle title="OFFICIAL LEAGUE" title-ko="공식 대회" />
          <div class="btn-group">
            <a v-if="!hideSchedule"
              class="btn-schedule"
              target="_blank"
              @click="showSchedule"
              v-prx:intro="{ r: [1, 4], ty: [20, 0], o: [0, 1] }"
            >
              <span>PUBG 대회일정</span>
            </a> <a
            class="btn-ask"
            href="https://discord.gg/VCb8BQBNVw"
            target="_blank"
            @click="trackEvent(`${$route.meta.leagueCode}_Main_discord_btn`, 'click', `${upRouteGameId}`)"
            v-prx:intro="{ r: [1, 4], ty: [20, 0], o: [0, 1] }"
          >
            <span>대회 | 팀 찾기</span>
          </a>
          </div>
        </div>
        <slot name="detail" />
      </div>
      <slot name="hero" />
    </PSection>
    <PSection v-if="cardList" class="slider-holder league" theme="white">
      <div class="card-list-holder">
        <div class="msg" v-if="routeParamsId === 'val' && (cardList || []).length"><b>원하는 날짜</b>에 참여 해보세요! <em>(예선 1,2차 중복참여 가능)</em></div>
        <Slider
          v-if="(cardList || []).length"
          :option="option"
          ref="slider"
          class="card-slider"
          @transitionEnd="transitionEnd"
          @transitionStart="transitionStart"
          @touchIn="touchStart"
          @touchOut="touchEnd"
          :info="cardList"
          navigation slides-per-view="auto"
        >
          <template v-slot:slide="{info, idx}">
            <OfficialGameCard
              :key="info.id"
              :item="info"
              :data-id="idx"
              :class="['card']"
              @click="selectedCard($event, idx)"
            />
          </template>
        </Slider>
        <NoData v-else text="등록된 경기가 없습니다." />
        <div class="arrow-wrap" v-if="!isHideSliderNav">
          <button class="arrow-left" :class="{'end': selectedId === 0}" @click="sliderMove(-1)">
            <img src="/img/pages/icon/Arrow-left.svg" alt="prev" />
          </button>
          <button class="arrow-right" :class="{'end': selectedId === cardList.length-1}" @click="sliderMove(1)">
            <img src="/img/pages/icon/Arrow-right.svg" alt="next" />
          </button>
        </div>
      </div>
    </PSection>
    <slot name="scrim" />
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import Slider from '@shared/components/common/Slider.vue';
import gtag from '@shared/mixins/gtag';
import ripple from '@/directives/ripple';
import SectionTitle from '@/views/components/common/SectionTitle.vue';
import OfficialGameCard from './OfficialGameCard.vue';
import NoData from '@/views/components/common/NoData.vue';

export default {
  name: 'OfficialLeague',
  mixins: [gtag],
  props: {
    step: { type: Number, default: 0 },
    ended: { type: Boolean, default: false },
    hideSchedule: { type: Boolean, default: false },
  },
  directives: { ripple },
  components: {
    OfficialGameCard,
    SectionTitle,
    Slider,
    NoData,
  },
  data() {
    return {
      week: -1,
      slider: null,
      selectedId: 0,
      resizeId: null,
      touchId: null,
      delayId: null,
      delay: false,
      startedX: 0,
      today: null,
      init: true,
      cardList: null,
      option: {
        slidesPerView: 'auto',
        preventClicks: true,
        preventClicksPropagation: false,
        observer: true,
        observeParents: true,
        touchRatio: 1,
        freeMode: true,
        spaceBetween: 12,
      },
      leagueTab: ['all', 'gold', 'diamond', 'radiant'],
    };
  },
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
    matchedMedia() {
      return this.$store?.state?.browser?.matchedMedia;
    },
    routeParamsId() {
      return this.$route.params.id;
    },
    base() {
      return this.$store.state.baseInfo;
    },
    isHideSliderNav() {
      return ['DS', 'DM', 'DL'].includes(this.matchedMedia) && this.cardList.length <= 5;
    },
  },
  methods: {
    getMatrixX() {
      const t = this.$el.querySelector('.card-slider .swiper-wrapper');
      const { transform } = t.style;
      const r = /translate3d\((?<x>.*?)px, (?<y>.*?)px, (?<z>.*?)px/;
      const v = r.exec(transform);
      return v ? v.groups.x : 0;
    },
    selectedCard($e, id) {
      if (Math.abs(this.startedX - this.getMatrixX()) > 10) return;
      clearTimeout(this.touchId);
      this.cardSorting($e, id);
    },
    transitionEnd() {
      if (this.delay) return;
      this.cardSorting();
    },
    transitionStart() {
      clearTimeout(this.touchId);
    },
    touchStart() {
      this.startedX = this.getMatrixX();
      this.delay = false;
      clearTimeout(this.delayId);
    },
    touchEnd() {
      if (Math.abs(this.startedX - this.getMatrixX()) < 10) return;
      this.touchId = setTimeout(() => {
        this.cardSorting();
      }, 100);
    },
    sliderMove(v) {
      if (this.ended) return;
      const items = this.$el.querySelectorAll('.card.swiper-slide');
      let startId = v.length ? Number(v[0]) : Number(v);
      startId = v.length ? startId : this.selectedId + startId < 0 ? 0 : this.selectedId + startId >= items.length ? items.length - 1 : this.selectedId + startId;

      this.cardSorting(items[startId], startId);

      if (v.length > 1) {
        _.forEach(v, o => {
          items[o].classList.add('on');
        });
      }
    },
    cardSorting($e, id) {
      if (this.ended) return;
      const mSize = window.innerWidth < 1024;
      const items = this.$el.querySelectorAll('.card.swiper-slide');
      const box = this.$el.querySelector('.card-slider .swiper-container');
      const wrapper = this.$el.querySelector('.card-slider .swiper-wrapper');
      if (!box) return;
      const boxHarf = box.clientWidth / 2;

      let total = 0;
      items.forEach(item => (total += item.clientWidth + (mSize ? 26 : 40)));
      total -= mSize ? 12 : 30;

      const max = (total - box.clientWidth) * -1;
      const coverX = Number(this.getMatrixX());
      let itemId = Math.floor(items.length * (coverX / max)) || 0;
      itemId = itemId >= items.length ? items.length - 1 : itemId < 0 ? 0 : itemId;
      const target = $e || items[itemId];
      if (!target) return;
      const { x } = target.getBoundingClientRect();

      items.forEach(item => {
        if (item === target) {
          item.classList.add('on');
        } else {
          item.classList.remove('on');
        }
      });

      let v = coverX - (x + target.clientWidth / 2 - (mSize ? 40 : 100) - boxHarf);
      v = v > 0 ? 0 : v < max ? max : v;
      wrapper.style.transform = `translate3d(${v}px, 0, 0)`;
      wrapper.style.transitionDuration = '300ms';

      this.selectedId = Number(id) >= 0 ? id : itemId;
      this.delay = true;
      this.delayId = setTimeout(() => {
        this.delay = false;
      }, 1000);
    },

    async getCardList(v) {
      if (this.$refs.slider) {
        const wrapper = this.$el.querySelector('.card-slider .swiper-wrapper');
        wrapper.style.transform = 'translate3d(0, 0, 0)';
      }
      if (this.week === v) return;
      this.week = v;
      const params = { q: `groupCode eq ${this.$route.meta.leagueCode}`, order: 'schedule.start ASC' };

      const res = await this.$services.home.getArena(params);
      this.cardList = res.items;

      const ids = [];
      _.forEach(this.cardList, (o, id) => {
        if (moment(o.schedule.start).format('MM-DD') === this.today) ids.push(id);
      });

      if (this.$route.params.id === 'pubg' && !ids.length) {
        const onGoingId = _.findIndex(this.cardList, o => o.statusTag === 'ONGOING' || o.statusTag !== 'END');
        if (onGoingId >= 0) ids.push(onGoingId);
      }

      this.selectedId = ids.length ? ids[0] : 0;

      setTimeout(() => {
        this.init = false;
        if (this.$route.params.id === 'pubg') {
          this.sliderMove(ids);
        } else {
          this.sliderMove(0);
        }
      }, this.init ? 700 : 300);
    },
    resize() {
      clearTimeout(this.resizeId);
      if (!this.ended) {
        this.resizeId = setTimeout(this.cardSorting, 600);
      }
    },
    async showSchedule() {
      this.trackEvent(`${this.$route.meta.leagueCode}_Main_calendar_btn`, 'click', `${this.upRouteGameId}`);
      this.$emit('scheduleModal');
    },
  },
  mounted() {
    this.today = moment().format('MM-DD');

    if (!this.ended) {
      this.selectedId = this.step;
      this.getCardList(this.selectedId);
      window.addEventListener('resize', this.resize);
    }
  },
  beforeDestroy() {
    clearTimeout(this.delayId);
    clearTimeout(this.touchId);
    clearTimeout(this.resizeId);
    window.removeEventListener('resize', this.resize);
  },
};
</script>
<style lang="less">
@import '~@/less/proj.less';
[official-league] { .c(white); overflow: hidden; .rel; .z(1); .bgc(#24262c); .p(0, 0); .no-repeat('@{pubg-home}/background.jpg'); .bg-xy(center, top);.bg-s(1542, auto);
  .progress-holder { .pt(60); .ph(20); .rel;
    .intro-up(3, {
      &:before {.o(0)}
    });
    &:before { .cnt; .abs; .r(0); .t(0); .b(0); .l(0); .z(0); .bgc(rgba(0, 0, 0, .1)); .o(1); .tr-o(.75s, 0s); pointer-events: none; backdrop-filter: blur(5px); }
    .inside { .rel; .z(1);
      .title { .flex; justify-content: space-between; .max-w(335); .mh-c;
        [section-title] { .pl(20); .rel; z-index: 3; .ib; .vam; .mt(6); .mr(22); .hide;}
        .btn-group { .ib; .z(2); .wh(100%); .flex; justify-content: flex-end; .gap(10);
          > a {.wh(160, 40); .ib; .br(8); .lh(40); .vam; .fs(14); .font-spoqa;}
          .btn-schedule { .bgc(#5b5c60); .tc; }
          .btn-ask { .bgc(#5b91f5); .rel; .pl(56);
            &:before {.cnt; .wh(24); .lt(23, 50%); .t-yc_; .abs; .contain('@{icon}/discord.svg'); }
          }
        }
      }
    }
  }
  .slider-holder { .rel; z-index: 2; transition: .5s; .t-y(0); .o(1); .pb(20); .ani-base;
    .intro-up(1, { .ani-on;});
    .card-list-holder { .h(630); .mt(60);

      .tab { z-index: 2; .tc; .mb(50); .pr(18); .pl(18);
        > button { .font-g; .medium; .bgc(#3d3f45); .c(rgba(255, 255, 255, .5)); .br-t(8); .wh(78, 52); .fs(16, 16);
          &.on { .c(black); .bgc(white); }
          & + button { .ml(4); }
        }
        &:after { .cnt; .wh(100%, 1); .bgc(#3d3f45); margin: 0 auto; .-box; }
      }
      [official-game-card] {
        .card-holder .title h4 { text-shadow: none; }
        .char-holder {
          &:before { .cnt; .abs; .r(0); .b(0); .l(0); .z(2); .h(298); .o(.4);
            background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 100%);
          }
        }
      }

      //&[data-i='-']{.t-y(200); .o(0);}
      [slider] {.tc; .-box; .p(0, 20); .pb(25); .min-h(546);
        .swiper-container {.ib; width: auto; overflow: visible; .max-w(100%);
          .swiper-wrapper {width: auto;
            .swiper-slide { .max-w(262);
              &:nth-of-type(1) {.ml(0);}
            }
            > .swiper-slide {.flex; .flex-jc;}
          }
        }
      }
      [no-data] { .mt(-25); .bgc(transparent);
        > p { .fs(15); .c(#ffffffa1); }
      }
      .arrow-wrap { .abs; .b(309); left: 0; z-index: 1; .w(100%); .block !important;
        .end { .hide;}
        img {.vam; .wh(8, 15);}
        .arrow- {
          &left {.abs;.lt(20, 0);z-index: 1;.wh(36);.br(50%);.bgc(rgba(255, 255, 255, 0.1));
            > img { .wh(22); .o(.8);}
          }
          &right {.abs;.rt(20, 0);z-index: 1;.wh(36);.br(50%);.bgc(rgba(255, 255, 255, 0.1));
            > img { .wh(22); .o(.8);}
          }
        }
      }
    }

  }
  @media (@tl-up) {
    .bg('@{pubg-home}/background.jpg'); .bg-s(1920, auto);
    .progress-holder { .p(100, 0, 0);
      .inside {
        .title { .w;
          .btn-group { .w(40%); .fs(14, 48px); justify-content: flex-end; .pr(58); .gap(20);
            span {.ib;
              em {.m(0, 10);}
            }
            > a { .wh(142, 48); .lh(48);}
            .btn-ask { .pl(48);
              &:before {.l(19); .t-y(-50%); .mt(1);}
            }
          }

          [section-title] { .mb(0); .pl(58); .block;}
        }
      }
      .bg {.h(95%); .o(.3);}
    }
    .slider-holder { .pt(0); .p(56, 58, 50);
      .card-list-holder { .h(810); .mt(100);
        .tab { .mt(0); .mb(82); .tl; .pr(0); .pl(0);
          > button { .wh(200, 56); .fs(20, 16);
            & + button { .ml(8); }
          }
          // &:after { .cnt; .wh(100%, 1); .bgc(#3D3F45); }
        }
        [slider] { .p(0); .min-h(626);
          .swiper-container {
            .swiper-wrapper {
              .swiper-slide {.max-w(340);}
            }
          }
        }
        [no-data] {
          > p { .fs(18); }
        }
        .arrow-wrap { .block; .b(434);
          .arrow- {
            &left { .wh(52);
              > img { .wh(32);}
            }
            &right { .wh(52);
              > img { .wh(32);}
            }
          }
          img { .wh(12, 21);}
        }
      }
    }
  }

  @media (@ds-up) {
    .pb(140);
    .progress-holder { .p(100, 0, 0);
      .inside { .w(@screen-ds-min); .mh-c; .rel;
        .title {
          .btn-group { .pr(0);
            > a { .wh(180, 48);}
            .btn-schedule {}
            .btn-ask { .pl(67);
              &:before { .l(33);}
            }
          }
          [section-title] { .mb(0); .pl(0);}
        }
      }
      .bg {.h(110%); .o(1);}
    }
    .slider-holder { .rel; z-index: 2; .p(5, 0, 100); .mb(0);
      .card-list-holder { .w(@screen-ds-min); .h(644); margin-right: auto; margin-left: auto;
        .tab { .mb(86); }
        .bts-week {.tl;}
        [slider] {.pt(0);}
        .arrow-wrap { .lb(50%, 320); .max-w(1260); .t-xc_;
          .arrow- {
            &left { .lt(auto, 0); .r(calc(100% + 95px)); }
            &right { .rt(auto, 0); .l(calc(100% + 95px)); }
          }
        }
      }
    }
  }
}
</style>
