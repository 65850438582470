export default [
  '#8B0E0F',
  '#CC6600',
  '#C48516',
  '#2E9974',
  '#21726C',
  '#1B5496',
  '#2E318E',
  '#AE0C68',
  '#48494D',
  '#000000',
  '#69110c',
  '#aa3e02',
  '#964f02',
  '#774f2b',
  '#87623e',
  '#906511',
  '#bc9f0f',
  '#8b770c',
  '#86930c',
  '#656e0a',
  '#18574d',
  '#074249',
  '#257353',
  '#005366',
  '#18607e',
  '#187eb2',
  '#10426b',
  '#282a66',
  '#4a31a8',
  '#44168e',
  '#732999',
  '#bc5091',
  '#811049',
  '#393b37',
  '#171906',
];
