<template>
  <div my-arena>
    <Slider v-if="isLogin && slideArenaLists.length" :info="slideArenaLists" :option="slideOption" :pagination="showPagination" pagination-type="bullets" navigation slides-per-view="auto">
      <template v-slot:slide="{info}">
        <div class="swiper-slide">
          <InfoGameCard v-for="item in info" :key="'my-info-game-' + item.id" :item="item" />
        </div>
      </template>
    </Slider>
    <NoData v-else :src="'/img/pages/icon/Arenas.svg'" :text="isLogin ? '대회 참여 내역이 없습니다.' : '로그인 하여 대회 참여 내역을 확인하세요!'">
      <ButtonRipple v-if="!isLogin" @click="login">로그인 하러가기</ButtonRipple>
    </NoData>
  </div>
</template>

<script>
import Slider from '@shared/components/common/Slider.vue';
import NoData from '@/views/components/common/NoData.vue';
import ButtonRipple from '@/views/components/common/button/ButtonRipple.vue';
import InfoGameCard from './InfoGameCard.vue';

export default {
  name: 'MyArena',
  components: { Slider, InfoGameCard, NoData, ButtonRipple },
  computed: {
    isLogin() {
      return this.$store.getters['auth/isLogin'];
    },
    slideArenaLists() {
      let cnt = 0;
      const result = [];
      for (let i = 0; i < 3; i++) {
        if (this.arenas.length < cnt + 1) break;
        result[i] = [];
        for (let j = 0; j < 3; j++) {
          if (!this.arenas[cnt]) break;

          result[i].push(this.arenas[cnt]);
          cnt++;
        }
      }

      return result;
    },
    showPagination() {
      return this.slideArenaLists.length > 1;
    },
  },
  props: {
    arenas: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      slideOption: {
        spaceBetween: 10,
        slidesPerView: 1,
      },
    };
  },
  methods: {
    login() {
      this.$services.auth.oAuthLogin();
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[my-arena] { .rel; .mt(50);
  [no-data] {
    button.btn-primary {.wh(147, 40);.fs(14);.c(#fff);background: linear-gradient(135deg, #2c81ff 0%, #b0aaff 100%);border-radius: 24px;}
  }

  .pagination { .abs; .rt(0, -54); z-index: 1;
    > .swiper-pagination-bullet { .wh(8); .m(0, 3); .o(0.1); background: #000; transition: opacity 0.3s;
      &-active {.o(1);}
    }
  }

  @media (@tl-up) {
    [no-data] {
      button.btn-primary { .wh(147, 48); .fs(16);}
    }
    .pagination {.t(-70);
      > .swiper-pagination-bullet {.wh(12);}
    }
  }
  @media (@ds-up) {
    .pagination {.t(-70); }
  }
}
</style>
