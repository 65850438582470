export default {
    CREATE_TEAM: '팀을 생성했습니다.',
    CHANGE_NAME: '팀명을 변경했습니다.',
    TEAM_JOIN_REQUEST: '님이 가입 신청했습니다.',
    TEAM_JOIN_CANCEL: '님이 가입 신청을 취소했습니다.',
    TEAM_JOIN_CONFIRM: '님이 팀에 합류했습니다.',
    TEAM_JOIN_FAIL: '님이 가입 탈락했습니다.',
    TEAM_MEMBER_DISMISS: '멤버를 추방했습니다.',
    TEAM_MEMBER_LEAVE: '멤버가 탈퇴했습니다.',
    TEAM_CHANGE_CAPTAIN: '캡틴 변경을 요청했습니다.',
    TEAM_CHANGE_CAPTAIN_CONFIRM: '캡틴이 변경되었습니다.',
    ARENA_APPLY: '아레나를 참가했습니다.',
    ARENA_APPLY_CANCEL: '아레나 참가를 취소했습니다.',
    APPLY_CHANGE_LEADER: '로스터 리더를 변경했습니다.',
    APPLY_CHANGE_MEMBER: '로스터 멤버를 변경했습니다.',
    APPLY_EXCLUDE_ROSTER: '로스터를 제외 처리했습니다.',
    APPLY_EXCLUDE_MEMBER: '로스터 멤버가 제외처리 되었습니다.',
    TEAM_ARENA_BLACKLIST: '블랙리스트 처리되었습니다.',
    SUCCESS_TEAM_BG_UPDATE: '팀 배경 이미지를 변경했습니다.',
    SUCCESS_TEAM_BG_RESET: '팀 배경 이미지를 초기화했습니다.',
    FAIL_TEAM_BG_UPDATE: '팀 배경을 변경하지 못했습니다.',
  };
