<template>
  <PSection home-header>
    <div class="contents-holder">
      <div class="slider-holder" v-if="mainBanners">
        <Slider class="slide-bg" :info="mainBanners" navigation slides-per-view="auto" :pagination="bannerMoreOne" :loop="bannerMoreOne" pagination-type="bullets" ref="slider" :option="option" @touchIn="touchStart" @touchOut="touchEnd">
          <template v-slot:slide="{info}">
            <div class="swiper-slide">
              <div class="title-holder">
                <h1 v-html="info.styles.headCopy" />
                <p v-html="info.styles.subCopy" />
                <a class="btn" v-if="info.styles.useLink" @click="toggleSeeMore(info)"
                  :href="info.styles.link" target="_blank">더보기</a>
              </div>
              <p class="img-holder" :style="{backgroundColor:info.styles.wideBgColor}">
                <img class="hidden-ml-down" :src="getCloudFileUrl(info.styles.bannerImage)" />
                <img class="hidden-tp-up" :src="getCloudFileUrl(info.styles.mobileBannerImage || info.styles.bannerImage)" />
              </p>
            </div>
          </template>
        </Slider>
        <ul class="notice" v-if="banner">
          <li v-if="feed" class="ct-1" @click="moveNotice">
            <h4><em class="hidden-tv-down">{{ feedType === 'NOTICE' ? '공지사항' : '이벤트' }}</em></h4>
            <p>{{ feedTitle }}</p>
            <em class="status-tag my">{{ feedCreatedAt }}</em>
          </li>
          <li v-if="banner" class="ct-2" :class="{'use-link': bannerUseLink, 'has-sub-copy': bannerSubCopy}" :style="bannerBgStyle" @click="movePage(banner)">
            <div class="logo-image">
              <ImageAsset :url="bannerLogoImg" bg-color="transparent" fit="contain" />
            </div>
            <h4>{{ bannerHeaderCopy }}</h4>
            <span v-if="bannerSubCopy">{{ bannerSubCopy }}</span>
          </li>
        </ul>
      </div>
    </div>
  </PSection>
</template>
<script>
import Slider from '@shared/components/common/Slider.vue';
import gtag from 'shared/mixins/gtag';
import ImageAsset from '@/views/components/common/ImageAsset.vue';
import { getCloudFileUrl } from '@/utils/url';
import { timeForToday } from '@/utils/time';

export default {
  name: 'HomeHeader',
  components: { Slider, ImageAsset },
  mixins: [gtag],
  data() {
    return {
      activeIdx: 0,
      checkId: null,
      ov: 0,
      mainBanners: null,
      feed: null,
      banner: null,
      loopId: null,
      ready: false,
      option: {
        loop: true,
      },
    };
  },
  computed: {
    per() {
      if (!this.$el) return 0;
      const wh = window.innerHeight;
      let t = Math.abs(this.$el.getBoundingClientRect().top);
      t = t > wh ? wh : t;
      return (t / wh);
    },
    feedType() {
      if (this.feed.category) return this.feed.category.value || null;
      return null;
    },
    feedTitle() {
      return this.feed.title || null;
    },
    feedCreatedAt() {
      if (this.feed.createdAt) return timeForToday(this.feed.createdAt);
      return '-';
    },
    bannerLogoImg() {
      if (this.banner.styles) {
        const { logoImage } = this.banner.styles;
        return logoImage ? getCloudFileUrl(logoImage) : null;
      }
      return null;
    },
    bannerHeaderCopy() {
      if (this.banner.styles) return this.banner.styles.headCopy || null;

      return null;
    },
    bannerSubCopy() {
      if (this.banner.styles) {
        return this.banner.styles.subCopy?.trim() ? this.banner.styles.subCopy : null;
      }

      return null;
    },
    bannerUseLink() {
      if (this.banner.styles) return this.banner.styles.useLink || null;

      return null;
    },
    bannerBgImg() {
      if (this.banner.styles) {
        const { bannerImage } = this.banner.styles;
        return bannerImage ? getCloudFileUrl(bannerImage) : null;
      }
      return null;
    },
    bannerBgColor() {
      if (this.banner.styles) {
        return this.banner.styles.wideBgColor || null;
      }
      return null;
    },
    bannerBgStyle() {
      const backgroundColor = this.bannerBgColor ? `background-color:${this.bannerBgColor};` : '';
      const backgroundImage = this.bannerBgImg ? `background-image: url(${this.bannerBgImg});` : '';
      return backgroundColor + backgroundImage;
    },
    bannerMoreOne() {
      return this.mainBanners?.length > 1;
    },
    leagueCode() {
      return this.$route.meta.leagueCode;
    },
  },
  methods: {
    toggleSeeMore(item) {
      this.trackEvent(`${this.leagueCode}_Main_banner`, `${item.styles.headCopy}_click`, 'PUBG');
    },
    getCloudFileUrl,
    check() {
      const wh = window.innerHeight;
      let t = Math.abs(this.$el.getBoundingClientRect().top);
      t = t > wh ? wh : t;
      this.ov = (t / wh);
      this.checkId = requestAnimationFrame(this.check);
    },
    loop() {
      if (this.$refs.slider && this.mainBanners.length > 1) this.$refs.slider.next();
    },
    moveNotice() {
      this.trackEvent(`${this.leagueCode}_Main_banner_news`, 'click', 'PUBG');
      this.$router.push(`/b/pubg${this.$route.params.league ? `/${this.$route.params.league}` : ''}/newsFeed?category=NOTICE`);
    },
    movePage(banner) {
      if (!banner.styles.useLink) return;
      this.trackEvent(`${this.leagueCode}_Main_banner_sub`, 'click', 'PUBG');
      const hostPath = `${window.location.protocol}//${window.location.host}`;
      if (banner.styles.link.includes(hostPath)) this.$router.push(banner.styles.link.replace(hostPath, ''));
      else window.open(banner.styles.link);
    },
    touchStart() {
      clearInterval(this.loopId);
      this.loopId = null;
    },
    touchEnd() {
      if (!this.loopId) this.loopId = setInterval(this.loop, 5000);
    },
  },
  mounted() {
    this.ready = true;
    this.loopId = setInterval(() => this.loop(), 5000);
  },
  async asyncData({ route, services }) {
    const { leagueCode } = route.meta;
    const [main, sub] = await services.home.getHeaderBanners(leagueCode);
    return { mainBanners: main.sort((a, b) => b.bannerIndex - a.bannerIndex), feed: sub ? sub.feed : null, banner: sub ? sub.banner : null };
  },
  beforeDestroy() {
    cancelAnimationFrame(this.checkId);
    clearInterval(this.loopId);
  },
};
</script>
<style lang="less">
@import '~@/less/proj.less';
[home-header] { .rel;
  .contents-holder {.wh(100%, 556); .bgc(@title-black); .-box; overflow-x: hidden; // .pt(100);
    .slider-holder {.wh(100%); .rel; overflow: hidden;
      .swiper-container {.wh(100%);}
      .swiper-slide {.rel; .wh(100%);}
      .slide-bg {.abs; .wh(100%); .lt(0, 0);
        .title-holder { .w(100%); .p(0, 20); .c(white); .abs; .lt(0, 45px); z-index: 11;
          h1 { .fs(27, 34); .ls(-2); text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
            & + p {.fs(13, 20); .regular; .mt(16); text-shadow: 0 0 2px rgba(0, 0, 0, 0.6); }
            br { .block }
          }
          > a {.mt(20); .ib; .p(8, 15); .fs(13); .lh(13);}
        }
        .img-holder { .wh(100%); .abs; overflow: hidden; .rb(0, 0); z-index: 10;
          img {object-fit: cover; object-position: right; .h(100%); .abs; .b(0); .l(50%); .t-xc_;}
        }
      }
      .notice { width: calc(100% - 40px); .abs; .b(20); .l(50%); .t-xc_; z-index: 1;
        > li {.w(100%); .p(14, 21); .-box; .bgc(white); .br(12); .rel;
          h4 { .font-spoqa;}
          &.ct-1 {.mb(12); .ib-list; .pointer;
            > * {.vam;}
            h4 {.wh(17); .abs; .mr(10); .lt(12, 50%); .t-yc_; .ellipsis(1);
              &:before {.cnt; .abs; .wh(17); .contain('@{icon}/Notice.svg'); .lt(0, 0);}
            }
            > p {.fs(12, 24px); .rel; .w(100%); .pl(14); .pr(60); .ellipsis(1); .-box; .regular; .w(100%);}
            .status-tag {.wh(48, 24); .w; .p(0, 5); .bgc(#ebf5f7); .-a(rgba(57, 65, 69, 0.1)); .fs(12, 24px); .c(#3d3f45); .tc; .medium; .abs; .rt(12, 50%); .t-yc_;}
          }

          &.ct-2 { .p(24, 14); .pr(30%); background-size: cover; .h(80); .-box; overflow: hidden;
            &.use-link { .pointer; }
            h4 { .ellipsis(2); .w(60%); .pr(10); .abs; .lt(14, 50%); .t-yc_; .fs(16);}

            .logo-image {
              .w(252); .h(100%); .p(0, 0); .abs; .rt(0, 0); .flex; .flex-ai(center);
              [image-asset] {.br(0); .flex-jc(flex-end);}
              img {.max-h(80); .max-w(100%); width: auto;}

            }

            &.has-sub-copy { .p(19, 14);
              h4 { .block; position: unset; .t-y(0); .ellipsis(1); }
              span { .rel; .z(1); .block; .w(65%); .mt(4); .fs(14); .ellipsis(1); }
            }
          }
        }
      }
      .pagination { .rb(20, 180);}
    }
  }
  @media (@tl-up) {
    .contents-holder { .h(750); .pt(0);
      .slider-holder {
        .slide-bg {
          .title-holder { .w; .p(0, 0); .lt(58, 193); .r(0);
            h1 { .fs(52, 72px);
              & + p {.fs(20, 28);}
              br { .block; }
            }
            > a {.mt(32); .ib; .p(13, 16, 13, 16); .fs(16); .lh(16);}
          }
          .img-holder {.wh(100%); .rt(0, 0);
            img {object-fit: cover; object-position: right; .h(100%); .abs; .b(0); .l(50%); .t-xc_;}
          }
        }
        .notice { width: calc(100% - 116px); .b(40); .ib-list;
          > li {width: calc(50% - 7px); .p(32, 20); .-box; .bgc(white); .br(12); .h(120);
            &.ct-1 {.mb(0); .mr(14);
              h4 {.fs(20); .static; width: auto; .pl(28); .h(26); .t(0); .t-y(0);
                em {.block;}
                &:before {.wh(22, 22); .t(2);}
              }
              > p {.fs(16); .p(0); .mt(13);}
              .status-tag {.t-y(0); .rt(20, 32);}
            }

            &.ct-2 { .p(45, 20); .h(120);
              > h4 {.fs(20); .l(20);}
              .logo-image { .rt(0, 0);
                img {.max-h(120);}
              }

              &.has-sub-copy { .p(33, 20);
                span { .mt(10); .fs(16); }
              }
            }
          }
        }
        .pagination { .rb(58, 180);}
      }
    }
  }
  @media (@ds-up) {
    .contents-holder {
      .slider-holder { .w(100%);
        .slide-bg { .r(0);
          .swiper-slide {.w(100%);}
          .title-holder { .w(@screen-ds-min); .p(0, 20); .lt(50%, 200); .r(0); .t-xc_;}
          .img-holder { max-height: 1000px;
            > img { .r(-30vh);}
          }
        }
        .notice {.w(@screen-ds-min);
          > li {width: calc(50% - 8px); .p(32);
            &.ct-1 {.mr(16);
              > p {.fs(16); .p(0); .mt(13);}
              .status-tag { .rt(32, 32);}
            }
            &.ct-2 { .p(0, 32);
              > h4 {.fs(20);}
              .logo-image { .rt(0, 0);
                img {.max-h(120);}
              }

              &.has-sub-copy { .p(33, 32); }
            }
          }
        }
        .pagination {.w(@screen-ds-min); right: auto; .lb(50%, 180); .t-xc_; .tr; }
      }
    }
  }
}
</style>
