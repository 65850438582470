<template>
  <!-- aimg를 img처럼 별도의 스타일 추가작업 없이 사용하는 것이 목표이므로
   다운로드 기능 등 별도의 DOM이 붙는 작업은 wrapper를 별도로 구현할 것-->
    <img v-if="loadState === SUCCESS && ready" @click="$emit('click')" :style="customStyle" :src="url" :class="customClass" />
    <logo
      v-else-if="ready"
      @click="$emit('click')"
      :custom-style="customStyle"
      :title="title"
      :hash="hash"
      :custom-class="customClass"
      :width="resolutionX"
      :height="resolutionY"
    />
</template>

<script>
import { checkUrl, getCloudFileUrl } from '@/utils/url';
import Logo from './LogoTempSVG.vue';

export default {
  components: { Logo },
  props: {
    source: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    hash: {
      type: String,
      default: '',
    },
    resolutionX: {
      type: [Number, String],
      default: 0,
    },
    resolutionY: {
      type: [Number, String],
      default: 0,
    },
    retinaMagnification: {
      // 해상도 배율입니다. 가능하면 2~4 사이의 정수로 입력해서 사용해주세요.
      type: Number,
      default: 1,
    },
    customStyle: {
      type: [Object, String],
      default: function d() {
        return {
          backgroundSize: `${this.resolutionX}px ${this.resolutionY}px`,
        };
      },
    },
    customClass: {
      type: String,
      default: '',
    },
    retryMax: {
      type: Number,
      default: 3,
    },
    retryTime: {
      type: Number,
      default: 700,
    },
  },
  data: () => ({
    loadState: 'LOADING',
    retry: 0,
    ready: false,
  }),
  computed: {
    LOADING: () => 'LOADING',
    SUCCESS: () => 'SUCCESS',
    FAIL: () => 'FAIL',
    _url() {
      // 해상도 정보가 포함되지 않은 url
      if (this.source === '' || !this.source) return '';
      if (checkUrl(this.source)) return this.source;
      const url = getCloudFileUrl(this.source);
      return url;
    },
    url() {
      const resolutionX = Math.round(this.resolutionX) * this.retinaMagnification;
      const resolutionY = Math.round(this.resolutionY) * this.retinaMagnification;
      // 클라이언트 통신 부하를 줄이기 위해 임의로 해상도가 조절된 url
      let query = `d=${resolutionX}x${resolutionY}`;
      // retry가 바뀔 때마다 타임스탬프가 랜덤으로 바뀜
      if (this.retry >= 1) query = `${query}&retry=${this.retry}`;
      if (resolutionX > 0 && resolutionY > 0) return `${this._url}?${query}`;
      return this._url;
    },
    bgSize() {
      return `${this.resolutionX}px ${this.resolutionY}px`;
    },
    // redeploy
  },
  watch: {
    source: { handler: 'onSrcChange' },
  },
  mounted() {
    this.$nextTick(async () => {
      this.getImage();
      this.ready = true;
    });
  },
  methods: {
    getImage() {
      this.loadState = this.LOADING;
      if (this.url === '') {
        this.loadState = this.FAIL;
        return null;
      }
      this.$image = new Image();
      this.$image.onload = this.onLoad.bind(this);
      this.$image.onerror = this.onError.bind(this);
      this.$image.src = this.url;
    },
    abortRetry() {
      clearTimeout(this.$timer);
    },
    onRetry() {
      this.getImage.bind(this, this.url);
    },
    onLoad() {
      this.loadState = this.SUCCESS;
      this.abortRetry();
    },
    onError() {
      this.retry += 1;
      if (this.retry >= this.retryMax) {
        this.loadState = this.FAIL;
        this.abortRetry();
        return null;
      }
      this.$timer = setTimeout(this.onRetry.bind(this), this.retryTime);
    },
    onSrcChange(newUrl, oldUrl) {
      if (oldUrl === newUrl) return null;
      this.getImage();
    },
  },
};
</script>

<style></style>
