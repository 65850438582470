/* eslint-disable */

// http://www.cse.yorku.ca/~oz/hash.html
// https://stackoverflow.com/questions/11120840/hash-string-into-rgb-color
export const djb2 = (str = '') => {
  let hash = 5381;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) + hash + str.charCodeAt(i); /* hash * 33 + c */
  }
  return hash;
};

/**
 * @function
 * @param {String} md5hash
 * @return {Object} { r, g, b } object.
 * @example
 * hashToRgb('5dc4d72dde9aaa3ab12af0eb') === { r: 37, g: 140, b: 226 }
 */
export const hashToRgb = (md5hash = '') => {
  const _hash = djb2(md5hash);
  const r = (_hash & 0xff0000) >> 16;
  const g = (_hash & 0x00ff00) >> 8;
  const b = _hash & 0x0000ff;
  return { r, g, b };
};

/**
 * @function
 * @param {String} md5hash
 * @param {Number} min
 * @param {Number} max
 * @example
 * hashToNumber('5dc4d72dde9aaa3ab12af0eb', 0, 100) === 13
 */
export const hashToNumber = (md5hash = '', min, max) => {
  const hash = djb2(md5hash);
  if (!Number.isInteger(min)) throw Error('missing minimum');
  if (!Number.isInteger(max)) throw Error('missing maximum');
  return (Math.abs(hash) % (max - min)) + min;
};
