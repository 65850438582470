<template>
  <div class="tab">
    <button v-for="(tab, index) in tabs" :key="'tab-' + index" :class="{'on': value === index}" @click="changeTab(index, tab)">{{tab.label || tab}}</button>
  </div>
</template>

<script>
  export default {
    name: 'ToggleTab',
    props: {
      tabs: {
        type: Array,
        default: () => [],
      },
      value: {
        type: Number,
        default: 0,
      },
    },
    methods: {
      changeTab(index, tab) {
        this.$emit('input', index, tab);
      },
    },
  };
</script>

<style lang="less" scoped>
</style>
