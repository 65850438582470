<template>
  <div official-league-detail-template-22s3>
    <SectionTitle
      v-if="title"
      :title="title.title || title"
      :title-ko="titleKo"
      :more="titleMoreBtn"
      sm
      @button-action="$emit('more-click')"
    />
    <p class="sub-title" v-if="title.subTitle" v-html="title.subTitle" />
    <!--    <OfficialLeagueSlider v-if="slides.length > 0" :slides="slides" />-->
    <!--    <div v-else>-->
    <!--      <slot />-->
    <!--    </div>-->
  </div>
</template>

<script>
import SectionTitle from '@/views/components/common/SectionTitle.vue';

export default {
  name: 'OfficialLeagueGameInfoTemplate',
  components: { SectionTitle },
  props: {
    title: {
      type: [Object, String],
      default: null,
    },
    slides: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    titleKo() {
      if (this.title) {
        return this.title.titleKo || '';
      }

      return '';
    },
    titleMoreBtn() {
      if (this.title) {
        return Boolean(this.title.more);
      }

      return false;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[official-league-detail-template-22s3] {
  .sub-title { .fs(13, 17px); .c(rgba(255, 255, 255, .7)); .mt(24); word-break: keep-all;}
  @media (@tl-up) {
    .sub-title { .h(40); .fs(16, 20px);}
  }
  @media (@ds-up) {
    .sub-title {.mt(32); }
  }
}
</style>
