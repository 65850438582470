<template>
  <svg
    @click="$emit('click')"
    preserveAspectRatio="none"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 100 100"
    :width="width"
    :height="height"
    :style="customStyle"
    :class="customClass"
  >
    <defs v-if="!noPattern">
      <pattern :id="uniqueId" patternUnits="userSpaceOnUse" width="100" height="100">
        <image :xlink:href="patternUrl" width="100" height="100" />
      </pattern>
    </defs>
    <g transform="translate(0,0)">
      <rect width="100" height="100" :fill="backgroundColor" />
      <rect v-if="!noPattern" width="100" height="100" :fill="`url(#${uniqueId})`" />
      <text transform="translate(50,55)" fill="white" alignment-baseline="middle" text-anchor="middle" font-size="50">
        {{ title | titleFilter }}
      </text>
    </g>
  </svg>
</template>

<script>
import _padStart from 'lodash/padStart';
import { nanoid } from 'nanoid';
import LOGO_COLORS from '@/constants/logoColors';
import { hashToNumber } from '@/utils/hashConvert';

export default {
  data: () => ({ imageBlob: '' }),
  filters: {
    titleFilter(title) {
      return (title.length <= 2 ? title : title.slice(0, 2)).toUpperCase();
    },
  },
  props: {
    title: {
      type: String,
      default: 'X',
    },
    hash: {
      type: String,
      default: '5dc4d72dde9aaa3ab12af0eb', // when no hash is given
    },
    width: {
      type: [Number, String],
      default: 200,
    },
    height: {
      type: [Number, String],
      default: 200,
    },
    noPattern: {
      type: Boolean,
      default: false,
    },
    customStyle: {
      type: [Object, String],
      default: () => ({}),
    },
    customClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    uniqueId() {
      return `pattern-${nanoid()}`;
    },
    patternUrl() {
      const patternNumber = _padStart(hashToNumber(this.hash, 1, 18), 2, '0');
      // 본래 shared-assets를 사용하도록 되어있었으나, 배포 스크립트에서 일부 shared-asset이 유실되는 이슈가 있어서 수정
      // return this.$getAsset(`patterns/Pattern_${patternNumber}.png`);
      return `/img/asset/pattern-png/Pattern_${patternNumber}.png`;
    },
    backgroundColor() {
      // ---- predefined hex colors
      const index = hashToNumber(this.hash, 0, LOGO_COLORS.length - 1);
      return LOGO_COLORS[index];
    },
  },
};
</script>
