<template>
  <PSection pubg-scrim theme="white">
    <OfficialLeagueGameInfoTemplate :title="titleData" />
    <PItem class="contents-section">
      <div class="contents-holder">
        <button @click="goScrim(item)" class="contents-box" v-for="(item, index) in scrimList" :key="`item-${index}`">
          <CdnImg class="img" :src="item.lvupSpecial.page.scrimLogoUrl" />
          <div class="contents">
            <div class="title">{{ item.lvupSpecial.page.scrimEnName }}</div>
            <div class="ko-title">{{ item.lvupSpecial.page.scrimKoName }}</div>
          </div>
          <div class="detail">
            <span class="tag">{{ tag[item.lvupSpecial.lvupSpecialType] }}</span>
            <span class="count">{{ item.canApplyScrimCount || '-' }}</span>
          </div>
        </button>
      </div>
    </PItem>
  </PSection>
</template>

<script>
import CdnImg from '@shared/components/common/CdnImg.vue';
import { historyMove } from 'shared/utils/routerUtils';
import gtag from 'shared/mixins/gtag';
import OfficialLeagueGameInfoTemplate from './OfficialLeagueGameInfoTemplate.vue';
import { MAIN_URL } from '@/env';

export default {
  name: 'PubgScrim',
  mixins: [gtag],
  components: { OfficialLeagueGameInfoTemplate, CdnImg },
  data() {
    return {
      info: null,
      titleData: {
        title: 'SCRIM',
        titleKo: '스크림',
      },
      tag: {
        COMPOSITE_ARENA: '진행중',
      },
    };
  },
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
    scrimList() {
      return ['gpl_pubg_scrim', 'maru_scrim'].reduce((acc, slug) => {
        const result = this.info?.find(({ lvupSpecial }) => lvupSpecial.slug === slug);
        return result ? [...acc, result] : acc;
      }, []);
    },

  },
  methods: {
    goScrim(item) {
      this.trackEvent(`${this.$route.meta.leagueCode}_Main_${item.lvupSpecial.slug}`, 'click', `${this.upRouteGameId}`);
      historyMove(`${MAIN_URL}/channel/${this.$route.params.id}/${encodeURI(item.lvupSpecial.slug)}`);
    },
  },
  mounted() {
  },
  async asyncData({ services }) {
    const info = await services.special.getPubgScrims();
    return { info };
  },
};

</script>

<style lang="less">
@import '~@/less/proj.less';
[pubg-scrim] { .p(60, 20, 60); .rel;
  [section-title] { .c(black);
    .ko { .c(black);}
  }
  .contents-section { .ani-base;
    .intro-up(1, { .ani-on;});

    .contents-holder { .mt(32);
      .contents-box { .wh(100%, 90px); .p(21, 16, 21, 76); .rel; .mb(20); .br(20); .flex; justify-content: space-between; align-items: center; .c(inherit);
        .img { .block; .wh(48); .abs; .lt(16, 21);}
        .contents { .tl;
          .title { .font-g; .bold; .fs(16, 16); .mb(4); }
          .ko-title { .font-spoqa; .medium; .fs(16, 16); .mt(8); }
        }
        .detail {
          .tag { .block; .wh(47, 22); .-a(white, 2px); .br(12); .fs(11, 18px); .c(white); .tc; .bold;}
          .count { .block; .fs(20, 18); .bold; .mt(8); .tc;}
        }
        &:nth-of-type(1) { background: linear-gradient(to right, #cb62fd, #f85b5b);}
        &:nth-of-type(2) { background: linear-gradient(to right, #1199c4, #2dcafb);}
        &:nth-of-type(3) { background: linear-gradient(to right, #68d3b2, #d6d8d7);}
      }
    }

  }
  @media (@tl-up) {
    .pb(56);
    .contents-section {
      .contents-holder { .mt(40);
        .contents-box { .mb(24);
          .contents {
            .title { .fs(17, 17); }
            .ko-title { .mt(7); }
          }
        }
      }
    }
  }
  @media (@ds-up) {
    .p(120, 0); .max-w(@screen-ds-min); .mh-c;
    .contents-section {
      .contents-holder { .flex; flex-direction: row;
        .contents-box { .mr(19); .mb(0);
          &:last-of-type { .mr(0);}
        }
      }
    }

  }
}

</style>
