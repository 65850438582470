<template>
  <div no-data>
    <ImageAsset v-if="src" :url="src" :bg-color="'transparent'" />
    <p v-html="text" class="text"></p>
    <slot />
  </div>
</template>

<script>
import ImageAsset from '@/views/components/common/ImageAsset.vue';

export default {
  name: 'NoData',
  components: { ImageAsset },
  props: {
    src: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[no-data] { .flex-center !important; flex-direction: column; .min-h(320); .p(40, 0); .tc; .bgc(#ebebf0); .br(20);
  [image-asset] {
    > img { .max-w(30); .mh-c; object-fit: none; }
  }
  > p { .mt(10); .fs(14); .medium; .c(#191919); .lh(22); .tc; }
  > button { .mt(28); }

  @media (@tl-up) {
    .p(50, 0);
    [image-asset] {
      > img { .max-w(31); }
    }
    > p { .mt(12); .fs(14, 24); }
    > button { .mt(32); }
  }
}
</style>
