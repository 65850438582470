<template>
  <ul info-game-card @click="moveToArena">
    <li>
      <ListCardThumb info :image="image || '/img/asset/Poster_Default.jpg'" :status-tag="item.statusTag"
        :state="item.state" :schedule="item.schedule" :gameId="gameId" />
    </li>
    <li>
      <div class="date-box">
        <p class="month">{{ startMonth }}</p>
        <p class="date">{{ startDay }}</p>
      </div>
      <div class="title-holder">
        <div>
          <h3>{{ title }}</h3>
        </div>
      </div>
      <p class="info"><em>{{ startTime }} KST</em><em>{{ modeSize }}</em><em v-show="false">{{ endDateFormat }}</em></p>
    </li>
  </ul>
</template>

<script>
import { historyMove } from '@shared/utils/routerUtils';
import { customDateFormat, remainDate } from '@shared/utils/timeUtils';
import { MAIN_URL } from '@/env';
import ListCardThumb from '@/views/components/common/ListCardThumb.vue';

export default {
  name: 'InfoGameCard',
  components: { ListCardThumb },
  props: {
    item: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: {
    image() {
      const images = {
        SAMPLE_IMAGE1: '/img/asset/Poster_Default.jpg',
        SAMPLE_IMAGE2: '/img/asset/Poster_Default2.jpg',
      };

      return this.item.posterType !== 'CUSTOM_IMAGE' ? images[this.item.posterType] : this.item.images.lg || this.item.images.logo || this.item.images.md || this.item.images.sm;
    },
    backgroundColor() {
      return this.imageObj.backGroundColor;
    },
    title() {
      return this.item.title || '';
    },
    modeSize() {
      const size = this.item.management.mode;
      return `${size} V ${size}`;
    },
    startDateFormat() {
      return this.item.schedule ? customDateFormat(this.item.schedule.start, 'MM월 DD일') : '';
    },
    startMonth() {
      return this.item.schedule ? customDateFormat(this.item.schedule.start, 'M월') : '';
    },
    startDay() {
      return this.item.schedule ? customDateFormat(this.item.schedule.start, 'DD') : '';
    },
    startTime() {
      return this.item.schedule ? customDateFormat(this.item.schedule.start, 'HH:mm') : '';
    },
    endDateFormat() {
      return this.item.schedule ? customDateFormat(this.item.schedule.end, 'MM월 DD일') : '';
    },
    remainStartTime() {
      const diffDay = remainDate(this.$store, this.item.schedule.start);
      return diffDay < 10 ? Number(diffDay.toString().padStart(2, '0')) : diffDay;
    },
    gameId() {
      return this.item?.gameId ?? '';
    },
  },
  methods: {
    moveToArena() {
      historyMove(`${MAIN_URL}/arena/${this.$route.params.id}/${this.item.id}/info`);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[info-game-card] { .ib-list; .h(96); .bgc(#ebebf0); .br(4); overflow: hidden; .pointer; transition: background-color 0.3s;
  &:hover {
    .bgc(#f0f0f3);
  }
  li { .h(100%);
    [list-card-thumb] {.wh(120, 100%);
      p {
        &:nth-child(1) {.abs;.lb(6, 6);z-index: 1;.p(3.5, 7.5);.fs(12);.c(#5b91f5);.bgc(#d4e2fc);border: 1px solid rgba(84, 132, 222, 0.1);.br(4);}
      }
      .lol-mark {.abs;.lt(6, 6);z-index: 1;.wh(24);.br(50%);.-a(#e3cb86);.bgc(#fff);.bg-c;.bg('@{icon}/lol_w.svg');.no-repeat; }
    }
    &:nth-of-type(2) {width: calc(100% - 120px); .rel;
      .date-box {.abs; .lt(10, 8);}
      .title-holder { .pt(8); .h(61); .flex; .flex-ai;
        > div { .vam; .w(100%); .h;
          h3 {display: -webkit-box; .vam; .fs(14, 20px); .pl(60); .pr(20); .mt(2); .w(100%); .-box; .ellipsis(2); .-box;}
        }
      }
      .info {.ib-list; .abs; .lb(10, 10); white-space: nowrap;
        em { .fs(11, 14px); .c(#191919); .pl(20); .rel; .ml(6);.medium;
          &:before {.cnt; .wh(1, 14); .bgc(rgba(0, 0, 0, .2)); .lt(0, 0); .abs; }
          &:after {.cnt; .wh(14); .contain('@{icon}/Arena_access_time.svg'); .abs; .lt(4, 0);}
          &:nth-of-type(1) {.ml(0);}
          &:nth-of-type(2):after {.contain('@{icon}/Arena_mode.svg');}
          &:nth-of-type(3):after {.contain('@{icon}/Arena_time.svg');}
        }
      }
    }
  }
  @media (@tp-up) {
    .h(148); .br(8); .pr(30); .crop;

    li {
      [list-card-thumb] { .w(230);
        p {
          &:nth-child(1) {.abs;.lb(10, 6);}
        }

        .lol-mark {
          .lt(12, 12);
          .wh(32);
        }
      }

      &:nth-of-type(2) {width: calc(100% - 250px);
        .date-box { .wh(60, 78); .abs; .lt(20, 10); .bgc(white); .br(8); .p(17, 0);
          &:after {.cnt; .wh(46, 66); .br(2); .bgc(#ebebf0); .abs; .lt(50%, 50%); .t-xyc; z-index: 1;}
        }
        .title-holder { .pt(10); .h(87);
          > div {.w(100%); .crop;
            h3 {.pl(100); .pr(50); .fs(22, 26px); .ellipsis(2); }
          }
        }
        .info {.lb(20, 10);
          em {.fs(15, 34px); .pl(42); .ml(16);
            &:before {.h(22); .t(5);}
            &:after {.wh(22);.lt(12, 50%); .t-yc_;}
          }
        }
      }
    }
  }
  @media (@ds-up) {
    .h(148); .br(8); .pr(30); .crop;

    li {
      [list-card-thumb] { .w(250);
        p {
          &:nth-child(1) {.abs;.lt(51, 16);}
        }
      }
      &:nth-of-type(2) {width: calc(100% - 250px);
        .date-box { .wh(60, 78); .abs; .lt(30, 36); .bgc(white); .br(8); .p(15, 0);
          &:after {.cnt; .wh(42, 60); .br(2); .bgc(#ebebf0); .abs; .lt(50%, 50%); .t-xyc; z-index: 1;}
        }
        .title-holder { .pt(18);
          > div {.w(100%); .crop;
            h3 {.pl(120); .pr(50); .mt(0); .fs(24, 28px); .ellipsis(1); }
          }
        }
        .info {.lb(120, 34);
          em {.fs(16, 34px); .pl(42); .ml(16);
            &:before {.h(24); .t(5);}
            &:after {.wh(24);.lt(12, 50%); .t-yc_;}
          }
        }
      }
    }
  }
}
</style>
