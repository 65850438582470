<template>
  <em> {{ remainTime }} </em>
</template>

<script>
import moment from 'moment';

export default {
  name: 'CountDown',
  props: {
    endTime: {
      type: Number,
      default: Date.now() + 10000000,
    },
  },
  data() {
    return {
      remainTime: '00 : 00 : 00',
    };
  },
  methods: {
    countDown() {
      const now = moment(moment().format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss');
      const end = moment(moment(this.endTime).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss');
      const diff = moment(end).diff(moment(now));

      if (diff < 0) {
        this.remainTime = '마감';

        return false;
      }

      const remain = moment.duration(diff);

      const days = +remain.asDays();
      const hours = +remain.asHours() >> 0;
      const minutes = (+remain.asMinutes() - (hours * 60)) >> 0;
      const seconds = +remain.asSeconds() - ((hours * 60 * 60) + (minutes * 60));

      if (days >= 1) {
        this.remainTime = `${days >> 0}일 남음`;
      } else {
        this.remainTime = `${hours.toString().padStart(2, '0')} : ${minutes.toString().padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`;
      }

      if (days <= 0) {
        this.remainTime = '마감';
      } else {
        setTimeout(() => this.countDown(), 1000);
      }
    },
  },
  mounted() {
    this.countDown();
  },
};
</script>

<style lang="less" scoped>

</style>
