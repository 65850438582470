import { render, staticRenderFns } from "./ImgLogo.vue?vue&type=template&id=66310c97&"
import script from "./ImgLogo.vue?vue&type=script&lang=js&"
export * from "./ImgLogo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports