// API URLS
export const AUTH_URL = process.env.VUE_APP_AUTH_URL;
export const API_URL = process.env.VUE_APP_API_URL;

// URLS
export const MAIN_URL = process.env.VUE_APP_HOME_URL;

// ENVS
export const ENV = process.env.NODE_ENV;
export const ENV2 = process.env.NODE_ENV2;
export const IS_LOCAL_ENV = ENV === 'local';
export const IS_DEVELOPMENT_ENV = ENV === 'development';
export const IS_PRODUCTION_ENV = ENV === 'production';
export const IS_STAGE_ENV = ENV === 'stage';

// COOKIES STORAGE KEYS FOR USER SESSION
export const STORAGE_URL = process.env.VUE_APP_STORAGE_URL;
export const STORAGE_KEY_USER = process.env.VUE_APP_STORAGE_USER_KEY;
export const STORAGE_KEY_ACCESS_TOKEN = process.env.VUE_APP_STORAGE_ACCESS_TOKEN_KEY;
export const STORAGE_KEY_AUTO_LOGIN = process.env.VUE_APP_STORAGE_AUTO_LOGIN_KEY;

// PUSHER KEY
export const PUSHER_KEY = process.env.VUE_APP_PUSHER_KEY;

// GA ID
export const GA_ID = process.env.VUE_APP_GA_ID;

// FOR RIOT GNB
export const STORAGE_KEY_RIOT_GAME_ID = process.env.VUE_APP_STORAGE_RIOT_GAME_ID;
